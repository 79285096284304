import customAlert from '@/utils/customAlert';
import axios from 'axios';

export function successHandler(cfg) {
  return cfg;
}

export async function errorHandler(cfg) {
  if (!cfg?.response?.status) {
    customAlert({
      type: 'error',
      msg: 'Network error'
    });
    return cfg;
  }

  if ([401, 403].includes(cfg?.response?.status) && cfg?.config && !cfg?.config?._retry) {
    const originalReq = cfg.config;
    originalReq._retry = true;
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const res = await axios.post(process.env.VUE_APP_API_URL+'/auth/refresh', {refresh_token: refreshToken}, null, false);
      localStorage.setItem('accessToken', res.data.access_token);
      localStorage.setItem('refreshToken', res.data.refresh_token);
      originalReq.headers['Authorization'] = `Bearer ${res.data.access_token}`;
      return axios(originalReq);
    } catch (err) {
      console.log(err)
      this.$customAlert({
        type: "error",
        msg: err?.response?.message || "Erro durante a atualização do token de acesso.",
      });
    }
  } 
  
  return cfg;
}
