import store from '../state/store';
import { isAuthenticated } from '../state/store';
import api from './api.services';
import customAlert from '../utils/customAlert';
import router from '@/router/index.js';

const authService = {
  login(credentials = {}) {
    return new Promise((resolve, reject) => {
      api
        .post('auth', {
          email: credentials.email,
          password: credentials.password,
          idCompany: credentials.idCompany
        }, null, false)
        .then((res) => {
          if ([200,201].includes(res?.status)) {
            store.commit('SET_AUTHENTICATED', true);
            this.updateSession(res.data);
            resolve(res);
            return;
          }
          reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout(slug, display_alert = true) {
    if (display_alert) {
      customAlert({
        msg: 'Sessão finalizada',
        type: 'success'
      });
    }
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    store.commit('SET_AUTHENTICATED', false);
    //router.push('/'+slug);
    router.push({ name: "companyPrincipal" });
  },
  
  updateSession(session = {}) {
    localStorage.setItem('accessToken', session.access_token);
    localStorage.setItem('refreshToken', session.refresh_token);
    localStorage.setItem('user', JSON.stringify(session.user));
    store.commit('SET_SESSION', session);
  },
  getSession() {
    if (isAuthenticated) {
      return {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        user: localStorage.getItem('user')
      };
    }
    return {};
  },
  getLoggedUser() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }
};

export default authService;
