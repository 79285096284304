
export default [
  {
    path: '/:slug',
    name: 'companyPrincipal',
    component: () => import('../views/pages/principal/companyPrincipal.vue')
  },
  {
    path: '/:slug/login',
    name: 'login',
    component: () => import('../views/pages/auth/login.vue')
  },
  {
    path: '/:slug/register',
    name: 'register',
    component: () => import('../views/pages/auth/register.vue')
  },
  {
    path: '/:slug/profile',
    name: 'companyProfile',
    component: () => import('../views/pages/principal/companyProfile.vue')
  },
  {
    path: '/:slug/company',
    name: 'companyRegister',
    component: () => import('../views/pages/principal/companyRegister.vue')
  },
  {
    path: '/:slug/categories',
    name: 'companyCategories',
    component: () => import('../views/pages/principal/companyCategories.vue')
  },
  {
    path: '/:slug/users',
    name: 'companyUsers',
    component: () => import('../views/pages/principal/companyUsers.vue')
  },
  {
    path: '/:slug/product/new',
    name: 'companyNewProduct',
    component: () => import('../views/pages/principal/companyFormProduct.vue')
  },
  {
    path: '/:slug/product/edit/:idProduct',
    name: 'companyEditProduct',
    component: () => import('../views/pages/principal/companyFormProduct.vue')
  },
  {
    path: '/:slug/product/:idProduct',
    name: 'companyProduct',
    component: () => import('../views/pages/principal/companyProduct.vue')
  },
  {
    path: '/:slug/products',
    name: 'companyProducts',
    component: () => import('../views/pages/principal/companyProducts.vue')
  }
]