import { useToast } from 'vue-toastification';

const toast = useToast();

function customAlert(cfg = { type: 'error', msg: '' }) {
  // Prevent show multiple alerts
  if (document.getElementsByClassName('Vue-Toastification__toast--error').length) return;

  toast[cfg.type](cfg.msg);
}

export default customAlert;