import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import modules from './modules'
import auth from '../services/auth.services';

export const isAuthenticated = localStorage.getItem('accessToken') ? true : false;

const store = createStore({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  state() {
    return {
      isAuthenticated: isAuthenticated,
      isLoading: false,
      session: auth.getSession(),
      currentCategory: null,
      currentCategoryName: null,
      cart: []
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.isAuthenticated = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_SESSION(state, session) {
      state.session = session;
    },
    SET_CURRENT_CATEGORY(state, value) {
      state.currentCategory = value;
    },
    SET_CURRENT_CATEGORY_NAME(state, value) {
      state.currentCategoryName = value;
    },
    ADD_TO_CART(state, product) {
      const existingProduct = state.cart.find(item => item._id == product._id);

      if (existingProduct) {
        existingProduct.qtd++;
      } else {
        state.cart.push({ ...product, qtd: 1 });
      }
    },
    CALCULATE_TOTAL_CART(state) {
      state.cartTotal = state.cart.reduce((total,item) => total + (item.promoPrice ? item.promoPrice : item.price) * item.qtd, 0);
    }
  },
  actions: {
    setAuthenticated({ commit }, value) {
      commit('SET_AUTHENTICATED', value);
    },
    setLoading({ commit }, value) {
      commit('SET_LOADING', value);
    },
    setSession({ commit }, value) {
      commit('SET_SESSION', value);
    },
    setCurrentCategory({ commit }, value) {
      commit('SET_CURRENT_CATEGORY', value);
    },
    setCurrentCategoryName({ commit }, value) {
      commit('SET_CURRENT_CATEGORY_NAME', value);
    },
    addToCart({ commit }, product) {
      commit('ADD_TO_CART', product);
      commit('CALCULATE_TOTAL_CART');
    }
  },
  getters: {
    cartItemCount: state => state.cart.length,
    cartTotal: state => state.cartTotal
  },
  plugins: [createPersistedState()]
})

export default store;

