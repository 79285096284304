import {
  createApp
} from "vue";
import App from "./App.vue";
import * as EvaIcons from "@stefandesu/eva-icons-vue"
import router from "./router";
import ActionCableVue from "actioncable-vue";
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import Maska from 'maska'
import Toast from 'vue-toastification';
import store from "./state/store";
import utils from './utils/functions';
import customAlert from './utils/customAlert';
import apiService from './services/api.services';
import authService from "./services/auth.services";
import VueLazyload from 'vue-lazyload';

import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/scss/custom/plugins/mermaid.min.css'
import 'vue-toastification/dist/index.css';
import "./assets/scss/app.scss";

const websocketOptions = {
  debug: true,
  debugLevel: "error",
  connectionUrl: "http://localhost:8080/",
  connectImmediately: false
};

const app = createApp(App);

app.config.globalProperties.$customAlert = customAlert;
app.config.globalProperties.$api = apiService;
app.config.globalProperties.$auth = authService;
app.config.globalProperties.$utils = utils;

app
  .use(store)
  .use(Toast, {})
  .use(EvaIcons)
  .use(router)
  .use(BootstrapVue3)
  .use(vClickOutside)
  .use(Maska)
  .use(VueLazyload)
  .use(ActionCableVue, websocketOptions)
  .mount("#app");