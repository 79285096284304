import axios from 'axios';
import { requestInterceptor } from './interceptors/requestInterceptor';
import { successHandler, errorHandler } from './interceptors/responseInterceptor';
import store from '../state/store';

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL;
axiosInstance.defaults.timeout = 150000;
axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(successHandler, errorHandler);

const defaultHeader = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

const apiService = {
  get(endpoint = '', headers = null, auth = false) {
   
    const cfg = this.getRequestConfig(headers, auth);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/${endpoint}`, cfg)
        .then((res) => {
          if (res?.status == 200) {
            resolve(res);
          } else {
            reject(res.response);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  post(endpoint, data = {}, headers = null, auth = true) {
    const cfg = this.getRequestConfig(headers, auth);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/${endpoint}`, data, cfg)
        .then((res) => {
          if (res?.status == 200 || res?.status == 201) {
            resolve(res);
          } else {
            reject(res.response);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  put(endpoint, data = {}, headers = null, auth = true) {
    const cfg = this.getRequestConfig(headers, auth);
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/${endpoint}`, data, cfg)
        .then((res) => {
          if (res?.status == 200) {
            resolve(res);
          } else {
            reject(res.response);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  destroy(endpoint, headers = null, auth = true) {
    const cfg = this.getRequestConfig(headers, auth);
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/${endpoint}`, cfg)
        .then((res) => {
          if (res?.status == 200) {
            resolve(res);
          } else {
            reject(res.response);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getRequestConfig(headers = null, auth = true) {
    return {
      headers: this.getHeaders(headers, auth)
    };
  },
  getHeaders(headers, auth) {
    const setAuth = auth ? this.getAuthorization() : null;
    const setHeader = headers ? headers : defaultHeader;
    return {
      ...setHeader,
      ...setAuth
    };
  },
  getAuthorization() {
    if (store.state.isAuthenticated) {
      return {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      };
    }
    return {};
  }
};

export default apiService;
