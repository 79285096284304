import moment from 'moment';
import customAlert from '@/utils/customAlert';
import api from '../services/api.services';
import store from '../state/store';

export const debounce = (func, wait = 500) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const toLower = (str = '') => {
  return str.toLowerCase().replaceAll(' ', '-');
};

export function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length;
}

export const formatMoney = (valor, showSimbol = true) => {
  //return `R$ ${parseFloat(v || 0).toFixed(2)}`;
  // Arredonda o valor para duas casas decimais
  valor = parseFloat(valor).toFixed(2);

  // Converte o valor para uma string e substitui o ponto por vírgula
  valor = valor.toString().replace('.', ',');

  // Adiciona o símbolo de R$ e formata as casas de milhar
  valor = `${showSimbol ? 'R$ ' : ''}${valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;

  return valor;
};

export const formatMoneyOnlyNumber = (v) => {
  if (parseFloat(v)) {
    return `R$ ${parseFloat(v || 0).toFixed(2)}`;
  }
  return v;
};

export const formatFloatOnlyNumber = (v) => {
  if (parseFloat(v)) {
    return parseFloat(parseFloat(v || 0).toFixed(2));
  }
  return v;
};

export const formatIntOnlyNumber = (v) => {
  if (parseInt(v)) {
    return parseInt(v || 0);
  }

  return v;
};

export function getObjectRaw(objmodel = {}) {
  const strObj = JSON.stringify(objmodel);
  return JSON.parse(strObj);
}

export const formatPercent = (v) => {
  return `${parseFloat(v || 0).toFixed(2)}%`;
};

export const formatFloat = (v) => {
  return parseFloat(parseFloat(v || 0).toFixed(2));
};

export const formatMoneyFloat = (v) => {
  let value = parseFloat(v || 0).toFixed(2);
  return `R$ ${value}`.replace('.', ',');
};

export const formatMoneyPositive = (v) => {
  let value = parseFloat(v || 0).toFixed(2);

  if (value < 0) {
    value = 0;
  }

  return `R$ ${value}`.replace('.', ',');
};

export const formatFloatPositive = (v) => {
  let value = parseFloat(v || 0).toFixed(2);

  if (value < 0) {
    value = 0;
  }

  return parseFloat(value);
};

export const formatDateTime = (v) => {
  if (!v) return '';
  const fullDate = moment(v).utc();
  return moment(fullDate).format('DD/MM/YYYY HH:mm:ss');
};

export const formatDate = (v) => {
  if (!v) return '';
  const fullDate = new Date(v);
  return moment(fullDate.toISOString().split("T")[0]).format('DD/MM/YYYY');
};

function emailValidation(email) {
  var re = /\S+@\S+\.\S+/;
  if (!re.test(email)) {
    customAlert({
      type: 'warning',
      msg: 'E-mail inválido'
    });
    return false;
  } else {
    return true;
  }
}

function validateDateOfBirth(dateOfBirth, minAge, maxAge) {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  const age = today.getFullYear() - birthDate.getFullYear();

  if (age < minAge) {
    customAlert({
      type: 'warning',
      msg: `O usuário deve ter no mínimo ${minAge} anos`
    });
    return false;
  }
  if (age > maxAge) {
    customAlert({
      type: 'warning',
      msg: `O usuário deve ter no máximo ${maxAge} anos`
    });
    return false;
  }
  return true;
}

function validatePassword(password = '') {
  const minLength = 6;
  const hasLetters = /[a-zA-Z]/.test(password);
  const hasNumbers = /[0-9]/.test(password);
  //const hasUpperCase = /[A-Z]/.test(password);
  //const hasLowerCase = /[a-z]/.test(password);
  //const hasSpecialChars = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]/.test(password);

  if (password.length < minLength) {
    customAlert({
      type: 'warning',
      msg: `A senha deve conter no mínimo ${minLength} caracteres`
    });
    return false;
  }

  if (!hasLetters) {
    customAlert({
      type: 'warning',
      msg: 'A senha deve conter letras'
    });
    return false;
  }

  if (!hasNumbers) {
    customAlert({
      type: 'warning',
      msg: 'A senha deve conter números'
    });
    return false;
  }

  //if (!hasUpperCase) {
  //  customAlert({
  //    type: 'warning',
  //    msg: 'The password must have an uppercase character'
  //  });
  //  return false;
  //}

  //if (!hasLowerCase) {
  //  customAlert({
  //    type: 'warning',
  //    msg: 'Password must have a lowercase character'
  //  });
  //  return false;
  //}

  //if (!hasSpecialChars) {
  //  customAlert({
  //    type: 'warning',
  //    msg: 'Password must have a special character'
  //  });
  //  return false;
  //}

  return true;
}

function normalizeName(name) {
  name = name.replace(/\s+/g, '-');

  name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  name = name.toLowerCase();
  
  return name;
}

async function getCompanyBySlug(slug) {
  store.commit('SET_LOADING', true);
  try {
    const res = await api.get('company/slug/'+slug);
    store.commit('SET_LOADING', false);
    if (res.data[0]) {
      localStorage.setItem('company', JSON.stringify(res.data[0]));
    }
    return res.data[0];
  } catch (err) {
    store.commit('SET_LOADING', false);
    customAlert({
      type: "error",
      msg: err?.data?.message || "Erro durante consulta(getCompanyBySlug)",
    });
  }
}

async function getByCompany(entity, idCompany) {
  //store.commit('SET_LOADING', true);
  try {
    const res = await api.get(`${entity}/company/${idCompany}`);
    //store.commit('SET_LOADING', false);
    return res.data;
  } catch (err) {
    //store.commit('SET_LOADING', false);
    customAlert({
      type: "error",
      msg: err?.data?.message || "Erro durante consulta(getByCompany)",
    });
  }
}

export default {
  formatDate,
  formatDateTime,
  validateDateOfBirth,
  validatePassword,
  debounce,
  getObjectRaw,
  formatPercent,
  formatFloatOnlyNumber,
  formatMoneyPositive,
  formatMoneyOnlyNumber,
  formatMoney,
  formatFloat,
  formatIntOnlyNumber,
  formatFloatPositive,
  checkIfDuplicateExists,
  formatMoneyFloat,
  emailValidation,
  toLower,
  normalizeName,
  getCompanyBySlug,
  getByCompany
};
